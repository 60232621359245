import * as React from "react";
import Layout from "../../components/Layout";
import { TdsFiling } from "../../components/HTabs";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import Testimonial from "../../components/Testimonial";
import Hero from "../../components/Hero";
import About from "../../components/About";
import ImgContent from "../../components/GSTTemp/ImgContent";
import Document from "../../components/Document";
import Cta from "../../components/Cta";
// import Package from "../../components/GSTTemp/Package";

export default function TdsReturnFiling() {
  /* Sliders */
  const TdsSlider = [
    {
      header_color: false,
      content_color: false,
      marginTop: 30,
      background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
      header: `Online TDS Return Filing Online`,
      content: `Apply for TDF Return Filings with TODAYFILINGS Experts`,
      image: '/imgs/gifs/Tax-Image.gif',
      alt_tag: 'Best Online TDF Return E-filings Service In Hosur',
    },
    // {
    //   header_color: false,
    //   content_color: false,
    //   marginTop: 10,
    //   background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
    //   header: `Simple TDS Return Filings Steps`,
    //   content: `Register Online TDS Return E-filing simple and few steps`,
    //   image: '/imgs/business/sliders/pvt-slide.png',
    //   alt_tag: 'Best TDS Return Filing Near Bangalore',
    // },

    // {
    //   header_color: false,
    //   content_color: false,
    //   marginTop: 40,
    //   background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
    //   header: `TDF Return E-filings In Hosur`,
    //   content: `One the top most success rated TDF return filings service in Hosur`,
    //   image: '/imgs/business/sliders/pvt.png',
    //   alt_tag: 'TODAYFILINGS TDS Return Filing Service',
    // },
  ];

  /* Package Data */
  // const PackageCompData = {
  //   header: `TDS Return Filing`,
  //   buyBtnLink: `#pricing-buy`,
  //   price: `6999`,
  //   content: [
  //     {
  //       points: `PAN & TAN Registration `,
  //       icon: "/imgs/icons/gst.png",
  //       alt_tag: "PAN & TAN Registration",
  //       background: "",
  //       plus: "+",
  //     },
  //     {
  //       points: `PF + ESIC + Professional Tax`,
  //       icon: "/imgs/icons/itr_filing.png",
  //       alt_tag: "TODAYFILINGS ITR E-filing service",
  //       background: "bg-f78acb",
  //       plus: "+",
  //       plus_dnone: "d-none d-md-block",
  //     },
  //     {
  //       points: `MSME Registration`,
  //       icon: "/imgs/icons/enterprises.png",
  //       alt_tag: "MSME Registration",
  //       background: "bg-eb6b3d",
  //       plus: "+",

  //     },
  //     {
  //       points: `Tax Consultancy Service `,
  //       icon: "/imgs/icons/consultant.png",
  //       alt_tag: "Tax Consultancy Service",
  //       background: "bg-c679e3",
  //     },
  //   ],
  // }
  /* About Data Without Form*/
  const TdsAboutData = {
    title: `TDS Return Filing`,
    sub_title: `Online TDS return filing in india with in 7 days.`,
    paragraph: `If the payment exceeds the established threshold limit, TDS,
    or Tax Deducted at Source, is a sort of tax that is deducted
    by the person making the payment. The rate at which the TDS
    filing must be remitted is managed and regulated by the
    income-tax department. The entity or person that withholds a
    specific amount is referred to as the Deductor, and the
    person from whom tax is withheld is referred to as the
    Deductee. TDS Return filing
    is made simple with TODAYFILINGS because we have the top
    professionals and only require online verification.`,
    header: `Our Features in TDS Return Filing in India`,
    points: [
      `Salaries`,
      `Rent payments`,
      `Professional fees`,
      `Consultation fees`,
      `Commission payments`,
      `Interest payments by banks`,
    ],
  };
  /* Img Content Component Data */
  const TdsIcData = {
    id: 'documents',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: `Advantages of Filing TDS Return Online in India`,
    image: '/imgs/assited/post-incorpration.png',
    alt_tag: 'professional tax registration',
    points: [
      {
        head: '',
        content: `According to the Income Tax Act of 1961, tds e filing is
        required for every person and every business entity`,
        icon: true,
      },
      {
        head: '',
        content: `The government can tag reports and records of the influx of income
        by receiving TDS Returns before or within the allotted deadline.`,
        icon: true,
      },
      {
        head: '',
        content: `Deductors who fall under the required slab are required to file TDS returns 
        each month, it gives the Indian government a reliable stream of income. The government 
        benefits from having a record of those who submit TDS on a regular basis.`,
        icon: true,
      },
      {
        head: '',
        content: `The funds obtained via the submission of the TDS Return are used for public welfare.`,
        icon: true,
      },
      {
        head: '',
        content: `Since the tax can be paid on a quarterly basis, there is no burden on
        any one person to pay the entire amount of tax all at once.`,
        icon: true,
      },
    ],
  };

  /* Document Section Data */
  const TdsDocData = {
    id: 'tds-doc',
    heading: `Online TDS Return Filing `,
    sub_heading: `Documents needed for TDS Return Filing Online in India`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-4`,
        delay: '100',
        img: `/imgs/icons/it/form.png`,
        alt_tag: `TAN`,
        header: `TAN`,
        description: `Account Number for Tax Collection and Deduction`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '200',
        img: `/imgs/icons/it/jobs.png`,
        alt_tag: `Address Proof`,
        header: `Address Proof`,
        description: `Address proof for the business registration`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '300',
        img: `/imgs/icons/it/employed.png`,
        alt_tag: `Incorporation Date`,
        header: `Incorporation Date`,
        description: `Date of the business's incorporation or registration`,
      },
    ],
  };
  /* FaQ data */
  var TdsReturnFAQ = [
    {
      header: 'What is TAN?',
      body: [
        {
          content: ` The abbreviation TAN stands for Tax Deduction and Collection Number.
          It is a special ten-digit alphanumeric number used to file TDS
          Returns. Before the month of TDS deduction, each person must submit
          an application for tax deduction allocation. If someone doesn't
          apply for or doesn't have a TAN, they could face fines of up to Rs.
          10,000.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is TDS?',
      body: [
        {
          content: `Tax Deducted at Source (TDS) is a tax collection mechanism in India, under the Income Tax Act of 
          1961, in which the tax is collected at the very source of income i.e. the point at which the income is 
          generated. Under this system, an individual or deductor who is responsible to make payment of prescribed 
          nature to any other individual or deductee shall deduct tax at source from deductee’s income and deposits 
          the same into the Central Government’s account on behalf of the deductee. TDS is one of the measures against
           tax-evasion practices and results in quick and efficient tax collection.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'It is compulsory to submit the PAN details?',
      body: [
        {
          content: `Yes, submitting a PAN is required of both employees and deductor's.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Is there any Software that can be Used to Prepare e-TDS/TCS Return?',
      body: [
        {
          content: `NSDL e-Gov provided Return Preparation Utility can be used to prepare e-TDS/TCS return. 
          This software is available for free. Besides, third party software can also be used for the e-filing 
          of TDS/TCS like Gen TDS software. Gen TDS is a certified software by SAG Infotech which can be used to
          perform any TDS related tasks accurately and quickly. Alike software is listed on the NSDL e-Gov -TIN 
          website i.e. www.tin-nsdl. com.`,
          icon: false,
        },
      ],
    },
    {
      header: `What is the penalty imposed for late filing the TDS return?`,
      body: [
        {
          content: `A fine of Rs. 200 is assessed for each day that a TDS return is not
          filed in accordance with Section 234E. Additionally, the fine should
          not be higher than the cost of completing a TDS return. According to
          section 271H, fines range from a minimum of Rs. 10,000 to a maximum
          of Rs. 1 lakh.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: `What is the bank branch code?`,
      body: [
        {
          content: `It is a distinct seven-digit number that the RBI has given to each
          bank branch. The bank branch code of the bank branch where the TDS
          is deposited must be mentioned by the applicant.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: `How can i fill the TDS return online?`,
      body: [
        {
          content: `Through the NSDL website, anyone can submit their TDS return online.
          You can seek our aid in the event of any disagreement or
          uncertainty.`,
          icon: false,
        },
      ],
    },
    {
      header: `Who can file the Tax Deducted at Source or TDS Return?`,
      body: [
        {
          content: `Any person who is having payment for prescribed goods or services required to file the 
          TDS Return. TDS is not needed to be subtracted by individuals and HUF or Hindu Undivided family.`,
          icon: false,
        },
      ],
    },
    {
      header: `Is Form No. 26Q submitted separately?`,
      body: [
        {
          content: `No, the Form 26Q is not filed separately. Along with Form No. 26Q, a separate annexure has to be 
          submitted for all the payments made to occupants.`,
          icon: false,
        },
      ],
    },
    {
      header: `How many types of TDS return filing are there?`,
      body: [
        {
          content: `Forms 24Q, 26Q, 27Q, 27EQ, 27D are all income tax return forms to declare Tax Deducted at Source
           (TDS) and have to be submitted to the Income Tax Department. Each one has a different purpose but connected 
           with income tax.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Tds Return Filing'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          <Hero sliders={TdsSlider} />
          {/* <Package packageData={PackageCompData} /> */}

          <About about_data={TdsAboutData} />

          <Counter />

          <ImgContent item={TdsIcData} />

          <Document docSecData={TdsDocData} />

          <section id='features' className='features'>
            <div className='container mt-5'>
              <div className='section-header'>
                <h2>Registration Procedure</h2>
                <div className='bar'></div>
              </div>
              <h4>The Procedure for Filing TDS Return in India</h4>
              <p>
                The procedure of filing a TDS Return includes detailed
                information on the amount deposited by the Deductor and the
                total TDS deducted, as well as the TAN and PAN of the Deductor
                and Deductee and information about the challan.
              </p>
            </div>
            <TdsFiling />
          </section>
          <Cta />
          <FAQAccord items={TdsReturnFAQ} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
